import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import TitledText from '../TitledText';
import ContactForm from './ContactForm';
import '../../style/contact.css';

function ContactPage() {
  const { t } = useTranslation();
  return (
    <>
      <Row className="justify-content-center bg-gray-1">
        <Col xs={12} md={10} className="my-5">
          <Row className="justify-content-center mb-5">
            <Col xs={8} lg={6}>
              <TitledText
                title={t('contact.title')}
                text={t('contact.subtitle')}
                align="center"
              />
            </Col>
          </Row>
          <Row className="justify-content-center mb-">
            <Col xs={12} md={10}>
              <ContactForm />
            </Col>
          </Row>
          {/* <Row className="justify-content-center">
          <Col xs={12} md={10}>
              <ContactInfo />
            </Col>
          </Row> */}
        </Col>
      </Row>
    </>
  );
}

export default ContactPage;
