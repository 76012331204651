import React from 'react';
import { Col, Row } from 'react-bootstrap';
export default function Anketornek() {

  return (
    <>
      <Row className="justify-content-center py-5 bg-white">
      <Col xs={12} lg={12} className="my-0 text-center">
      <iframe frameborder="0" scrolling="no" width="100%" height="1000vmax" src="https://ahmet-utku-akbyk.shinyapps.io/anket_2023/"> </iframe >
        </Col>
      </Row>
    </>
  );
}
