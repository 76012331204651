import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Navbar, Nav, Col, Row, NavDropdown } from 'react-bootstrap';
import i18n from '../../i18n';
import logo from '../Asset 18.svg';

import { MdLanguage } from 'react-icons/md';

function Navigation() {
  const { t } = useTranslation('translation', { keyPrefix: 'very.deeply.nested' });
  const activeLink = {
    color: '#ad1445',
  };
  const selectLanguage = (language) => {
    if (language === 'ar') {
      i18n.changeLanguage('ar');
      document.documentElement.style.setProperty('--direction', 'rtl');
      document.documentElement.style.setProperty('--align', 'right');
    } else {
      i18n.changeLanguage(language);
      document.documentElement.style.setProperty('--direction', 'ltr');
      document.documentElement.style.setProperty('--align', 'left');
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Navbar
        data-testid="nav-parent"
        collapseOnSelect
        expand="lg"
        className="header d-lg-none p-0"
      >
        <Row className="w-100 m-0">
          <Col xs={3} className="d-flex align-items-center p-0">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          </Col>
          <Col xs={6} className="d-flex justify-content-center">
            <Navbar.Brand>
              <NavLink exact to="/">
                <img className="logo" src={logo} alt="Galata Anket logo" />
              </NavLink>
            </Navbar.Brand>
          </Col>
          <NavDropdown
              id="collasible-nav-dropdown"
              title=<MdLanguage />
              lang={i18n.language}
            >
              {/* <NavDropdown.Item onClick={() => selectLanguage('ar')}>
                العربية
              </NavDropdown.Item> */}
              <NavDropdown.Item onClick={() => selectLanguage('tr')}>
                Türkçe
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => selectLanguage('en')}>
                English
              </NavDropdown.Item>
            </NavDropdown>
        </Row>
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-center"
        >
          <Nav className="navbar-bg container text-center">
            <NavLink exact to="/" activeStyle={activeLink}>
              {t('nav.header.home')}
            </NavLink>
            <NavLink exact to="/about" activeStyle={activeLink}>
              {t('nav.header.about')}
            </NavLink>
            <NavLink exact to="/contact" activeStyle={activeLink}>
              {t('nav.header.contact')}
            </NavLink>
            <NavLink exact to="/anket" activeStyle={activeLink}>
              {t('nav.header.anket')}
            </NavLink>
            {/* <NavLink exact to="https://galataanket.anketkatilim.com/survey/BKvfQfvYXGAcQmAUhVHgqb?source=web" activeStyle={activeLink}>
              {t('nav.header.Sign_in')}
            </NavLink>
            
             */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Row className="header d-none d-lg-flex justify-content-between align-items-center">
        <Col md={4}>
          <NavLink exact to="/">
            <img className="logo" src={logo} alt="Galata Anket logo" />
          </NavLink>
        </Col>
        <Col md={6} xl={6} className="d-flex justify-content-end">
          {/* <a
            href="https://galataanket.anketekatil.com/survey/5NKpCKWWfTsecHrQkqBrnq?source=web" // Replace with your actual external link
            className="p-2 text-nowrap"
            style={{ backgroundColor: '#f0f0f0' }} // Replace with your desired background color

            rel="noopener noreferrer" // Recommended for security reasons
          >
            {t('nav.header.Sign_in')}
          </a>
           */}
          <NavLink
            exact
            to="/about"
            activeStyle={activeLink}
            className="p-2 text-nowrap"
          >
            {t('nav.header.about')}
          </NavLink>
          

          
          <NavLink
            exact
            to="/contact"
            activeStyle={activeLink}
            className="p-2 text-center text-nowrap"
          >
            {t('nav.header.contact')}
          </NavLink>
          <NavDropdown
            id="collasible-nav-dropdown"
            title=<MdLanguage />
            lang={i18n.language}
          >
            {/* <NavDropdown.Item onClick={() => selectLanguage('ar')}>
              العربية
            </NavDropdown.Item> */}
            <NavDropdown.Item onClick={() => selectLanguage('tr')}>
              Türkçe
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => selectLanguage('en')}>
              English
            </NavDropdown.Item>
            
          </NavDropdown>
          
        </Col>
        
      </Row>
    </>
  );
}

export default Navigation;
