import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import logoSample1 from '../../media/article.svg';
import '../style/newspage.css';
import TitledText from './TitledText';

// import { resetNextUuid } from 'react-accessible-accordion';
// import { useParams, useRouteMatch } from 'react-router-dom';

function Privacy() {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col className="post-header" />
      </Row>
      <Row className="news-post d-flex justify-content-center">
        <Col xs={10} className="depth-shadow">
          <Row>
            <Col md={{ span: 8, offset: 2 }} className="p-5 text-center">
              <TitledText title={t('nav.footer.policy')} titleLevel={2} />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }} className="p-3">
            <iframe src="https://www.dropbox.com/scl/fi/865m55fx01tcs61q99c3w/Galata-Anket-nternet-Sitesi-Gizlilik-ve-erez-Politikas.pdf?rlkey=2csqeg3s0sml3ynknltm5wq3k&raw=1" width="100%" height="800" frameborder="0"></iframe>


            </Col>
          </Row>

        </Col>
      </Row>
    </>
  );
}

export default Privacy;
