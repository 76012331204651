import React, { useState } from 'react';
import { jsPDF } from 'jspdf';
import { PDFDocument } from 'pdf-lib';
import Papa from 'papaparse'; // Ensure you have installed PapaParse
import '../../style/lottery.css';

import "jspdf-autotable";



const RandomNumberGenerator = () => {
  const [range, setRange] = useState({ min: 1, max: 100 });
  const [numberCount, setNumberCount] = useState(5);
  const [numbers, setNumbers] = useState([]);
  const [pdfName, setPdfName] = useState('Results');
  const [uploadedPdf, setUploadedPdf] = useState(null);
  const [uploadedCsvData, setUploadedCsvData] = useState([]);

  const generateRandomNumbers = () => {
    // Create an array of possible numbers based on the range
    const possibleNumbers = Array.from({ length: range.max - range.min + 1 }, (_, i) => i + range.min);
    
    // Shuffle the array
    for (let i = possibleNumbers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [possibleNumbers[i], possibleNumbers[j]] = [possibleNumbers[j], possibleNumbers[i]];
    }
  
    // Select the first 'numberCount' elements
    const randomNumbers = possibleNumbers.slice(0, numberCount);
  
    randomNumbers.sort((a, b) => a - b);

    setNumbers(randomNumbers);
  };

  const mergePDFs = async (generatedPdf, uploadedPdf) => {
    const combinedPdf = await PDFDocument.create();
    const generatedPdfDoc = await PDFDocument.load(generatedPdf);

    const copiedGeneratedPages = await combinedPdf.copyPages(generatedPdfDoc, generatedPdfDoc.getPageIndices());
    copiedGeneratedPages.forEach(page => combinedPdf.addPage(page));

    if (uploadedPdf) {
      const uploadedPdfDoc = await PDFDocument.load(await fetch(uploadedPdf).then(res => res.arrayBuffer()));
      const copiedPages = await combinedPdf.copyPages(uploadedPdfDoc, uploadedPdfDoc.getPageIndices());
      copiedPages.forEach(page => combinedPdf.addPage(page));
    }

    const pdfBytes = await combinedPdf.save();
    return pdfBytes;
  };

  const saveAsPDF = async () => {
    const logoUrl = '/Icon_Kare.png'; // URL of your logo in the public folder
  
    // Function to convert image to Base64
    const getBase64ImageFromUrl = async (imageUrl) => {
      const res = await fetch(imageUrl);
      const blob = await res.blob();
  
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          resolve(reader.result);
        }, false);
  
        reader.onerror = () => {
          return reject(this);
        };
        reader.readAsDataURL(blob);
      });
    };
  
    // Get the Base64 representation of the image
    const base64Logo = await getBase64ImageFromUrl(logoUrl);
    const doc = new jsPDF();
    const date = new Date().toLocaleDateString();
  
    doc.addImage(base64Logo, 'PNG', 10, 10, 20, 20); // Adjust position and size as needed
    doc.setFontSize(16);
    doc.text(`Tarih: ${date}`, 105, 30, null, null, 'center');
    doc.text(`Anket ismi: ${pdfName}`, 105, 40, null, null, 'center');
    doc.text(`Kazanan no:`, 105, 50, null, null, 'center');
  
    let startY = 60;
    const lineLength = 80; // Max length of each line

    let currentLine = '';
    const numbersText = numbers.join(", ");
    numbersText.split(', ').forEach(number => {
      if ((currentLine + number).length <= lineLength) {
        currentLine += number + ', ';
      } else {
        doc.text(currentLine, 105, startY, null, null, 'center');
        startY += 10; // Move to next line
        currentLine = number + ', ';
      }
    });
  
    if (currentLine) {
      doc.text(currentLine, 105, startY, null, null, 'center');
    }

  
  
     // Function to parse CSV string into an array of objects
     const parseCSV = (csvString) => {
      const lines = csvString.split('\n');
      const headers = lines[0].split(',');
      return lines.slice(1).map(line => {
        const data = line.split(',');
        return headers.reduce((obj, nextKey, index) => {
          let value = data[index] ? data[index].toString() : ''; // Convert to string and handle null/undefined
    
          if (nextKey === 'telefon' && value.length > 3) {
            obj[nextKey] = '*'.repeat(value.length - 3) + value.substr(-3);
          } else if (nextKey === 'e_mail' && value.length > 3) {
            obj[nextKey] = value.substr(0, 3) + '*'.repeat(value.length - 3);
          } else {
            obj[nextKey] = value; // If the length is less than or equal to 3, keep the value as is
          }
          return obj;
        }, {});
      });
    };
    
    

  // Create table data from CSV string
  let tableData = [];
  if (uploadedCsvData.length > 0) {
    const csv = Papa.unparse(uploadedCsvData);
    tableData = parseCSV(csv);
  }

  console.log("Table Data:", tableData); // Debugging: Check the parsed table data

  if (tableData.length > 0) {
    startY += 10; // Adjust start position for table

    // Add the table to the PDF
    doc.autoTable({
      head: [Object.keys(tableData[0])],
      body: tableData.map(Object.values),
      startY: startY,
    });
  }
  
    const generatedPdf = doc.output('arraybuffer');
    const mergedPdfBytes = await mergePDFs(generatedPdf, uploadedPdf);
    const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
    const mergedPdfUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = mergedPdfUrl;
    a.download = `${pdfName}_kamu_ile_paylas.pdf`;
    a.click();
  };
  
  

 

  const handleCsvUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type.includes("csv")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        parseCsv(e.target.result);
      };
      reader.readAsText(file);
    } else {
      alert("Please upload a CSV file.");
    }
  };

  const parseCsv = (csvData) => {
    Papa.parse(csvData, {
      header: true,
      complete: (results) => {
        setUploadedCsvData(results.data);
      }
    });
  };

  const saveFilteredCsv = () => {
    const filteredData = uploadedCsvData.filter(row => 
      numbers.includes(parseInt(row.cekilis_no))
    );
    const csv = Papa.unparse(filteredData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const csvUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = csvUrl;
    downloadLink.download =  `${pdfName}_kamu_ile_paylas.csv`;
    downloadLink.click();
  };

  return (
    <div className="random-number-generator">
      <h1 className="title">Anket Çekiliş</h1>
      <div className="input-group">
        {/* ... existing input fields for range and numberCount */}
        <label>
          Min:
          <input type="number" value={range.min} onChange={e => setRange({ ...range, min: parseInt(e.target.value) })} className="input-field" />
        </label>
        <label>
          Max:
          <input type="number" value={range.max} onChange={e => setRange({ ...range, max: parseInt(e.target.value) })} className="input-field" />
        </label>
        <label>
          Number Count:
          <input type="number" value={numberCount} onChange={e => setNumberCount(parseInt(e.target.value))} className="input-field" />
        </label>
        <label>
          PDF Name:
          <input type="text" value={pdfName} onChange={e => setPdfName(e.target.value)} className="input-field" />
        </label>
      </div>
      <br />
      Çekiliş yapılacak katılımcı CSV dosyasını yükle.<br />
      Dosyada olması gereken sütunlar: cekilis_no, uid, telefon, e_mail, anket_no
      
      
      <br />
      <div className="input-group">
        <input type="file" onChange={handleCsvUpload} className="upload-field" accept=".csv" />
      </div>
      <br />
      CSV yüklenince ve sayılar generate edilince, iki dosya üretecek. <br />
      1- websitesine yüklemek için sonuçlar PDF. <br />
      2- Kazananlara ulaşmak için katılımcı bilgileri CSV <br />
      Dosyaları kontrol et, 2'de kişisel bilgiler otomatik maskelenmiş olması lazım.
      <br />
      <button onClick={generateRandomNumbers} className="generate-btn">Generate Numbers</button>
      {numbers.length > 0 && (
        <div className="results">
          <button onClick={saveAsPDF} className="pdf-btn">Dosya 1 - Websitesine konulacak as PDF</button>
          {uploadedCsvData.length > 0 && (
            <button onClick={saveFilteredCsv} className="csv-btn">Dosya 2 - Kazananlar liste as CSV</button>
          )}
          <div className="number-list">Kazanan Numaralar: {numbers.join(', ')}</div>
        </div>
      )}
    </div>
  );
};

export default RandomNumberGenerator;
