import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import logoSample1 from '../../media/article.svg';
import '../style/newspage.css';
import TitledText from './TitledText';
// import { resetNextUuid } from 'react-accessible-accordion';
// import { useParams, useRouteMatch } from 'react-router-dom';
function Terms() {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col className="post-header" />
      </Row>
      <Row className="news-post d-flex justify-content-center">
        <Col xs={10} className="depth-shadow">
          <Row>
            <Col md={{ span: 8, offset: 2 }} className="p-5 text-center">
              <TitledText title={t('nav.footer.terms')} titleLevel={2} />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }} className="p-3">
            <iframe src="https://www.dropbox.com/scl/fi/c0mowiq3ysbxs9paj09cw/Galata-Anket-nternet-Sitesi-Ayd-nlatma-Metni.pdf?rlkey=y9w990if4qp6drftuinfmgpsy&raw=1" width="100%" height="800" frameborder="0"></iframe>

            </Col>
          </Row>

        </Col>
      </Row>
    </>
  );
}

export default Terms;
