import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HomeCover from './HomeCover';
import '../../style/home.css';

function HomePage() {
  const { t } = useTranslation();
  return (
    <>
      <HomeCover
        
        title={t('home.title')}
        description={t('home.subtitle')}
        btnText={t('home.request')}
        placeholder={t('home.form')}
      />
    </>
  );
}

export default HomePage;
