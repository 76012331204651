import React, { useState } from 'react';
import { jsPDF } from 'jspdf';
import { PDFDocument } from 'pdf-lib';
import '../../style/lottery.css';

const RandomNumberGenerator = () => {
  const [range, setRange] = useState({ min: 1, max: 100 });
  const [numberCount, setNumberCount] = useState(5);
  const [numbers, setNumbers] = useState([]);
  const [pdfName, setPdfName] = useState('Results');
  const [uploadedPdf, setUploadedPdf] = useState(null);

  const generateRandomNumbers = () => {
    let randomNumbers = [];
    for (let i = 0; i < numberCount; i++) {
      const randomNumber = Math.floor(Math.random() * (range.max - range.min + 1)) + range.min;
      randomNumbers.push(randomNumber);
    }
    setNumbers(randomNumbers);
  };

  const mergePDFs = async (generatedPdf, uploadedPdf) => {
    const combinedPdf = await PDFDocument.create();
    const generatedPdfDoc = await PDFDocument.load(generatedPdf);

    const copiedGeneratedPages = await combinedPdf.copyPages(generatedPdfDoc, generatedPdfDoc.getPageIndices());
    copiedGeneratedPages.forEach(page => combinedPdf.addPage(page));

    if (uploadedPdf) {
      const uploadedPdfDoc = await PDFDocument.load(await fetch(uploadedPdf).then(res => res.arrayBuffer()));
      const copiedPages = await combinedPdf.copyPages(uploadedPdfDoc, uploadedPdfDoc.getPageIndices());
      copiedPages.forEach(page => combinedPdf.addPage(page));
    }

    const pdfBytes = await combinedPdf.save();
    return pdfBytes;
  };

  const saveAsPDF = async () => {
    const logoUrl = '/Icon_Kare.png'; // URL of your logo in the public folder

    // Function to convert image to Base64
    
    const getBase64ImageFromUrl = async (imageUrl) => {
      const res = await fetch(imageUrl);
      const blob = await res.blob();
    
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          resolve(reader.result);
        }, false);
    
        reader.onerror = () => {
          return reject(this);
        };
        reader.readAsDataURL(blob);
      });
    }
    
    // Get the Base64 representation of the image without altering colors
    const base64Logo = await getBase64ImageFromUrl(logoUrl);
    const doc = new jsPDF();
  const date = new Date().toLocaleDateString();
  
  doc.addImage(base64Logo, 'PNG', 10, 10, 20, 20); // Adjust position and size as needed

  doc.setFontSize(16);
  doc.setFontSize(12);
  doc.text(`Tarih: ${date}`, 105, 30, null, null, 'center');
    doc.text(`Anket ismi: ${pdfName}`, 105, 40, null, null, 'center');
    doc.text(`Kazanan numaralar:`, 105, 50, null, null, 'center');
  
    let startY = 60;
    const lineLength = 80; // Max length of each line
    const numbersText = numbers.join(", ");
    let currentLine = '';
    
    numbersText.split(', ').forEach(number => {
      if ((currentLine + number).length <= lineLength) {
        currentLine += number + ', ';
      } else {
        doc.text(currentLine, 105, startY, null, null, 'center');
        startY += 10; // Move to next line
        currentLine = number + ', ';
      }
    });
  
    if (currentLine) {
      doc.text(currentLine, 105, startY, null, null, 'center');
    }
  

  const generatedPdf = doc.output('arraybuffer');
    const mergedPdfBytes = await mergePDFs(generatedPdf, uploadedPdf);
    const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
    const mergedPdfUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = mergedPdfUrl;
    a.download = `${pdfName}.pdf`;
    a.click();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedPdf(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please upload a PDF file.");
    }
  };

  return (
    <div className="random-number-generator">
      <h1 className="title">Anket Çekiliş</h1>
      
      { (
        <>
          <div className="input-group">
            <label>
              Min:
              <input type="number" value={range.min} onChange={e => setRange({ ...range, min: parseInt(e.target.value) })} className="input-field" />
            </label>
            <label>
              Max:
              <input type="number" value={range.max} onChange={e => setRange({ ...range, max: parseInt(e.target.value) })} className="input-field" />
            </label>
            <label>
              Number Count:
              <input type="number" value={numberCount} onChange={e => setNumberCount(parseInt(e.target.value))} className="input-field" />
            </label>
            <label>
              PDF Name:
              <input type="text" value={pdfName} onChange={e => setPdfName(e.target.value)} className="input-field" />
            </label>
          </div>
          <br />

          <div className="input-group">
            <input type="file" onChange={handleFileUpload} className="upload-field" />
          </div>
          <br />

          <button onClick={generateRandomNumbers} className="generate-btn">Generate Numbers</button>
          {numbers.length > 0 && (
            <div className="results">
              <button onClick={saveAsPDF} className="pdf-btn">Save as PDF</button>
              <div className="number-list">Random Numbers: {numbers.join(', ')}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RandomNumberGenerator;
