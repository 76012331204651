import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Toast } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import '../../style/contact.css';

function ContactPage() {

  const { t, i18n } = useTranslation();
  const handleFocus = (e) => {
    const input = e.target;
    const label = document.querySelector(`#label-${input.id.slice(6)}`);
    label.classList.add('active');
  };

  const handleBlur = (e) => {
    const input = e.target;
    const label = document.querySelector(`#label-${input.id.slice(6)}`);
    label.classList.remove('active');
    if (input.value) {
      label.classList.add('filled');
    } else {
      label.classList.remove('filled');
    }
  };

  const form = useRef();

  const successMessage = t('contact.submit.success');
  const failureMessage = t('contact.submit.failure');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_exohkma", "template_30tdzvu", form.current, "A2pgTFc93Cu0aXiC3").then((result) => {
      console.log(result.text);
      alert(successMessage);
    }, (error) => {
      console.log(error.text);
      alert(failureMessage);
    });
  };


  return (
    <Row className="justify-content-center p-3 depth-shadow">
      <Col md={{ span: 8 }}>
        <Form
          id="contact-form"
          autoComplete="off"
          onSubmit={sendEmail}
          lang={i18n.language}
          ref={form}
        >
          <Row className="pt-2 pt-md-4 px-3">
            <Col xs={12} md={6} className="p-0">
              <Form.Group controlId="input-name" className="m-2">
                <Form.Label id="label-name">{t('contact.form.name')}</Form.Label>
                <Form.Control
                  type="text"
                  name="contact_firstname"
                  required
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  size="lg"
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} className="p-0">
              <Form.Group controlId="input-surname" className="m-2">
                <Form.Label id="label-surname">
                  {t('contact.form.surname')}
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  name="contact_surname"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  size="lg"
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={12} className="p-0">
              <Form.Group controlId="input-email" className="m-2">
                <Form.Label id="label-email">{t('contact.form.email')}</Form.Label>
                <Form.Control
                  type="email"
                  name="contact_email"
                  required
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  size="lg"
                />
              </Form.Group>
            </Col>
            <Col xs={12} className="p-0">
              <Form.Group controlId="input-message" className="m-2">
                <Form.Label id="label-message">
                  {t('contact.form.message')}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="5"
                  name="message"
                  required
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  size="lg"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="text-center">
              <button type="submit" size="lg" className="btn p-2 my-4">
                {t('contact.form.submit')}
              </button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );

}




export default ContactPage;
