import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button } from 'react-bootstrap';
import TitledText from '../TitledText';
import coverImage from '../cover.jpg';

function HomeCover({ title, description, btnText, placeholder }) {
  const { i18n } = useTranslation();
  return (
    <Row className="home-cover d-flex align-items-center justify-content-around pt-md-5 pt-3 pb-5">
      {i18n.language === 'ar' ? <Col md={1} /> : null}
      <Col xs={8} xl={4} className="my-5">
        <TitledText
          title={title}
          text={description}
          isMainDescription={true}
        />
      </Col>
      <Col
        lg={12}
        xl={6}
        className={`d-flex justify-content-xl-end justify-content-center mt-lg-4
          ${i18n.language === 'ar' ? 'order-md-12' : null}`}
      >
        <img src={coverImage} alt="Galata Anket" />
      </Col>
    </Row>
  );
}

HomeCover.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  btnText: PropTypes.string.isRequired,
};

export default HomeCover;
