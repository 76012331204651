import React from 'react';
import PropTypes from 'prop-types';

function TitledText({
  title,
  highlight,
  text,
  textLinked,
  titleLevel,
  align,
  className,
  isMainDescription,
  isSecondaryDescription,
}) {
  const Header = `h${titleLevel}`;
  const padding = 4;
  // const Span = `<span>${highlight}</span>`

  return (
    <div className={`titled-text ${className !== null ? className : ''}`}>
      <Header
        className={`my-2 my-md-${padding} ${align ? `text-${align}` : ''}`}
        data-testid="titled-text-title"
        id="test"
      >
        {title}{' '}
        <span className={highlight ? 'highlight-pink' : ''}>{highlight}</span>
      </Header>
      {text ? (
        <p
          className={`my-2 my-md-${padding} ${align ? `text-${align}` : ''} ${
            isMainDescription ? 'main-description' : ''
          } ${isSecondaryDescription ? 'secondary-description' : ''}`}
          data-testid="titled-text-paragraph"
        >
          {text}
        </p>
      ) : null}
      {textLinked}
    </div>
  );
}

TitledText.propTypes = {
  title: PropTypes.string.isRequired,
  highlight: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  titleLevel: PropTypes.number,
  align: PropTypes.string,
  className: PropTypes.string,
  isMainDescription: PropTypes.bool,
  ishighlight: PropTypes.bool,
  word: PropTypes.array,
};

TitledText.defaultProps = {
  text: null,
  highlight: null,
  titleLevel: 1,
  align: null,
  className: null,
  isMainDescription: null,
  isSecondaryDescription: null,
};

export default TitledText;
