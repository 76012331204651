import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
// import i18n from '../../i18n';
import logo from '../Asset 19.svg';

import { BsTwitter, BsLinkedin, BsFacebook, BsInstagram, BsWhatsapp } from "react-icons/bs";

function Footer() {
  const { t } = useTranslation();

  return (
    <>
      <Row id="footer" className="footer p-5 ">
        <Col
          xs={12}
          md={6}
          lg={12}
          className="pb-4 d-flex justify-content-center align-items-center footer-border"
        >
          <NavLink exact to="/">
            <img className="logo" src={logo} alt="Galata Anket logo" />
          </NavLink>
        </Col>
        <Col className="d-flex justify-content-between p-0">
          <p>
            <span className="p-2">
            <a href="/terms_conditions_all/Galata Anket - İnternet Sitesi Aydınlatma Metni.pdf" target="_blank" rel="noopener noreferrer">
            {t('nav.footer.terms')}
            </a>
            </span>
            <span className="p-2">
            <a href="/terms_conditions_all/Galata Anket - İnternet Sitesi Gizlilik ve Çerez Politikası.pdf" target="_blank" rel="noopener noreferrer">
            {t('nav.footer.policy')}
            </a>
            </span>
            <span className="p-2">
            <a href="/terms_conditions_all/Galata Anket - İnternet Sitesi İlgili Kişi Başvuru Formu.pdf" target="_blank" rel="noopener noreferrer">
            {t('nav.footer.electronic')}
            </a>
            </span>
            <span className="p-2">
              <NavLink exact to="/contact">
                {t('nav.footer.contact')}
              </NavLink>
            </span>
            <p>
            <span className="p-2 small">
            <NavLink exact to="/contact">
                {t('nav.footer.address')}
              </NavLink> Yıldız Teknik Üniversitesi Teknopark Çifte Havuzlar Mahallesi Eski Londra Asfaltı Caddesi Kuluçka Merkezi A1 BLOK NO:151/1C/B35 ESENLER/İSTANBUL
             </span>
           </p>

          </p>
          <p>
          
            <span className="p-2 lead">
            <a href="https://twitter.com/galataanket" target="_blank"><BsTwitter /></a>  
            </span>
            <span className="p-2 lead">
              <a href="https://www.facebook.com/galataanket/" target="_blank"><BsFacebook /></a>  
            </span>
            <span className="p-2 lead">
              <a href="https://www.instagram.com/GalataAnket/" target="_blank"><BsInstagram /></a>  
            </span>
            {/* <span className="p-2 lead">
              <a href="https://wa.me/message/VM54F5U5NCPYB1" target="_blank"><BsWhatsapp /></a>  
            </span> */}
                        
          </p>
        
        </Col>
        
      </Row>
    </>
  );
}

export default Footer;
