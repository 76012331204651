import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import TitledText from '../TitledText';
import '../../style/contact.css';

function ResultPage() {
  const { t } = useTranslation();
  return (
    <>
      <Row className="justify-content-center bg-gray-1">
        <Col xs={12} md={10} className="my-5">
          <Row className="justify-content-center mb-5">
            <Col xs={8} lg={10}>
              <TitledText
                title={t('result.title')}
                text={t('result.subtitle')}
                align="center"
              />
            </Col>
          </Row>
          <Row className="justify-content-center mb-">
          <Col xs={12} md={10}>
          <a href="/sonuclar/Kultur_kamu_ile_paylas.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#ad1445' }}>
      Kültür Anketi Çekilişi, 4 Ağustos 2024
      </a>  
      <br />
    
            <a href="sonuclar/Gazete_kamu_ile_paylas.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#ad1445' }}>
      Gazete Anketi Çekilişi, 5 Haziran 2024
      </a>
      <br />
      <a href="sonuclar/Instagram_Fenomen_Anket_kamu_ile_paylas.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#ad1445' }}>
      Sosyal Medya Kullanımı Çekilişi, 28 Şubat 2024
      </a>

      <br />
      <a href="sonuclar/Beslenme_Tercihleri_kamu_ile_paylas.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#ad1445' }}>
      Beslenme Tercihleri Çekilişi, 10 Kasım 2024
      </a>
            </Col>
          </Row>
          {/* <Row className="justify-content-center">
          <Col xs={12} md={10}>
              <ContactInfo />
            </Col>
          </Row> */}
        </Col>
      </Row>
    </>
  );
}

export default ResultPage;
