import React, { useLayoutEffect, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
// import { browserHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCircle,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faWhatsapp,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import Header from './components/Header';
import HomePage from './components/Home';
import ContactPage from './components/Contact';
import ResultPage from './components/Results';
import LotteryPage from './components/Lottery';
import MaillistPage from './components/maillist';

import Footer from './components/Footer';
import PageNotFound from './components/PageNotFound';
import About from './components/About';
import TermsPage from './components/Terms';
import Electronic from './components/Electronic';
import Katilimci_aydinlatma_metni from './components/Katilimci_aydinlatma_metni';

import PrivacyPage from './components/Privacy';
import Anketgazete from './components/anketgazete';
import Anketornek from './components/Anketornek';


function App() {
  const { i18n } = useTranslation();
  const [classNameLanguage, setClassNameLanguage] = useState();
  useLayoutEffect(() => {
    if (i18n.language === 'ar') {
      document.documentElement.style.setProperty('--direction', 'rtl');
      document.documentElement.style.setProperty('--align', 'right');
      setClassNameLanguage('');
    } else if (i18n.language === 'tr') {
      setClassNameLanguage('turkishFont');
      document.documentElement.style.setProperty('--direction', 'ltr');
      document.documentElement.style.setProperty('--align', 'left');
    } else {
      document.documentElement.style.setProperty('--direction', 'ltr');
      document.documentElement.style.setProperty('--align', 'left');
      setClassNameLanguage('');
    }
  }, [i18n.language]);
  // Add needed fontawesome icons to library
  library.add(
    faCircle,
    faAngleLeft,
    faAngleRight,
    faArrowLeft,
    faArrowRight,
    faFacebookF,
    faInstagram,
    faLinkedinIn,
    faWhatsapp,
    faTwitter,
  );

  return (
    <div className={`container-fluid ${classNameLanguage}`}>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" render={() => <HomePage />} />
          <Route exact path="/about" render={() => <About />} />
          <Route exact path="/contact" render={() => <ContactPage />} />
          <Route exact path="/results" render={() => <ResultPage />} />
          <Route exact path="/lottery" render={() => <LotteryPage />} />
          <Route exact path="/maillist" render={() => <MaillistPage />} />
          <Route exact path="/terms" render={() => <TermsPage />} />
          <Route exact path="/electronic" render={() => <Electronic />} />
          <Route exact path="/Katilimci_aydinlatma_metni" render={() => <Katilimci_aydinlatma_metni />} />
          
          <Route exact path="/privacy" render={() => <PrivacyPage />} />
          <Route exact path="/anketgazete" render={() => <Anketgazete />} />
          <Route exact path="/anketornek" render={() => <Anketornek />} />
          <Route path="/404" render={() => <PageNotFound />} />
          <Redirect to="/404" />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
